<template>
  <div style="width: 100%">
    <div class="leisure_buttons_group">

      <router-link tag="button" class="btn_minigame_comp bw3"
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_SUREMAN}"
                   :to="{path:'/front/minigame/suremanpowerball1m', query: {t: new Date().getTime()}}">
        <img src="../../../assets/images/icon/cenha/mini/skypark.png"/> 슈어맨
      </router-link>
      <router-link tag="button" class="btn_minigame_comp bw3 "
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_NEXT}"
                   :to="{path:'/front/minigame/next_ring1m', query: {t: new Date().getTime()}}">
        <img src="../../../assets/images/icon/cenha/mini/next.png"/> 넥스트
      </router-link>
      <router-link tag="button" class="btn_minigame_comp bw3"
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_BET365}"
                   :to="{path:'/front/minigame/bet365_superleague', query: {t: new Date().getTime()}}">
        <img src="../../../assets/images/icon/cenha/mini/Bet365.png"/> BET365
      </router-link>

    </div>

    <div class="leisure_buttons_group">
      <router-link tag="button" class="btn_minigame_comp bw2"
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_DONGHANG}"
                   :to="{path:'/front/minigame/donghangpowerballrandom', query: {t: new Date().getTime()}}">
        <img src="../../../assets/images/icon/cenha/mini/ntry.png"/> 동행복권
      </router-link>


      <router-link tag="button" class="btn_minigame_comp bw2"
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_STAR_SADALI}"
                   :to="{path:'/front/minigame/ssd1', query: {t: new Date().getTime()}}">
        <img src="../../../assets/images/icon/cenha/mini/bos.png"/> 보스코어
      </router-link>
      <!--      <router-link tag="button" class="bw3 btn_minigame_comp "-->
      <!--                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_NAMED}"-->
      <!--                   :to="{path:'/front/minigame/npowerball3s', query: {t: new Date().getTime()}}">-->
      <!--        <img src="../../../assets/images/icon/cenha/mini/named.png"/> 네임드-->
      <!--      </router-link>-->

    </div>
  </div>


</template>
<script>
import {tgamebetcount} from "@/network/userRequest";
import sportsConst from "@/common/sportsConst";
import leisureConst from "@/common/leisureConst";


export default {
  name: "LeisureGameCompLinks",
  data() {
    return {
      leisureConst: leisureConst,

    }
  },
  methods: {
    alertTgame() {
      alert('토큰게임은 유료게임 입니다. 고객센터 텔로 문의주세요');
    },
    commingsoon() {
      alert('준비중입니다')
    },
  },
  created() {

  }
}
</script>

<style scoped>
.leisure_buttons_group img {
  width: 25px;
}
</style>